*,
html {
   font-family: poppins, sans-serif;
}
* {
   padding: 0;
   margin: 0;
}

p {
   margin: 0;
   padding: 0;
}

body {
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   overflow-x: hidden;
}
