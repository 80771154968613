*,
ol,
ul {
  padding: 0;
  margin: 0;
}

/* Navbar */

.old-logo {
  display: none;
}


.arrows a:hover {
  color: #fff;
  margin-bottom: -50px;
  position: relative;
  top: 7px;
}

.arrows a,
.arrows a:hover {
  text-decoration: none;
}
.arrows a:hover:after {
  display: block;
  position: relative;
  width: 100%;
  text-align: center;
  content: "\25B2";
  margin-top: -10px;
  color: #44d62c;
  top: 10px;
}

.mainnav {
  display: flex;
  /* flex-direction: column; */
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 120px;
  padding: 12px 180px 12px 100px;
  gap: 10px;
  color: #44d62c;
  position: relative;
  z-index: 5;
}

.container-nav {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: space-between;
}

.navigation {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
}

nav .logo {
  display: flex;
  height: auto;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

nav .logo img {
  /* width: 500px; */
  height: 70px !important;
}

nav ul {
  display: flex;
  flex-direction: row;
}

nav ul .btn-tanya {
  width: 160px;
  height: 40px;
  padding: 8px 18px 9px;
  border-radius: 10px;
  /* background-color: #44d62c; */
  background-color: transparent;
  border: solid 1px #44d62c;

  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
  /* border: none; */
}

nav ul .btn-language {
  width: 40px;
  height: 40px;
  padding: 8.5px 12px;
  border-radius: 10px;
  background-color: transparent;
  border: solid 1px #44d62c;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
  color: #fff;
}

.navigation {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* gap: 60px; */
  width: 100%;
  padding: 0 100px;
}

.navigation ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.navigation span {
  font-size: 18px;
}

/* Navbar onScroll */

.navbarOnScroll {
  width: 100%;
  height: 80px;
  background-color: yellow;
  box-sizing: border-box;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;
  padding: 12px 30px;

  animation-name: slideDown;
  -webkit-animation-name: slideDown;

  animation-duration: 1s;
  -webkit-animation-duration: 1s;

  animation-timing-function: ease;
  -webkit-animation-timing-function: ease;
}

@keyframes slideDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}
.navbarOnScroll .logo img {
  max-width: 250px;
}

.navbarOnScroll .logo,
.navbarOnScroll .btn-language,
.navbarOnScroll .btn-tanya {
  display: none;
}

.navbarOnScroll .mainnav {
  height: 80px;
}

.navbarOnScroll .navigation {
  margin-top: 0px;
}

.isActive span span:nth-child(1) {
  color: #fff;
}

nav ul {
  display: flex;
  gap: 20px;
  list-style: none;
  align-items: center;
}

/* Menu */
nav ul li a {
  color: #44d62c;
  text-decoration: none;
  font-size: 16px;
  /* text-transform: uppercase; */
  font-weight: 400;
}

nav ul li a:hover {
  color: #fff;
}

ul li.tentang {
  margin-right: -30px;
}

.change-language {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  border: solid 1px #44d62c;
  cursor: pointer;
}

.change-language:hover {
  background-color: gray;
}

/* Menu Active */

.arrow-active {
  position: absolute;
  top: 20px;
  display: none;
}

/* Dropdown Menu Active */

/* dropdown hover */
ul li.tentang .hover-dropdown {
  display: flex;
  flex-direction: column;
  gap: 25px;

  position: absolute;
  min-width: 260px;
  overflow: hidden;
  height: 0;
  border-radius: 15px;
  box-shadow: 0 3px 30px 0 rgba(13, 84, 136, 0.15);
  background-color: #fff;

  z-index: 2;
}
ul li.tentang .hover-dropdown a {
  text-transform: capitalize;
  color: #272d3b;
  width: 100%;
}

ul li.tentang .hover-dropdown a:hover {
  color: #44d62c;
}

ul li.tentang:hover .hover-dropdown {
  padding: 20px;
  min-height: 100px;
  min-width: 260px;
  z-index: 3;
  /* transition: animation 1s;
  animation: fadeIn; */
  transition: height 0.2s;
}

/* Hamburger menu */

.menu-toggle {
  display: none;
  flex-direction: column;
  height: 20px;
  justify-content: space-between;

  position: relative;
}

.menu-toggle input {
  position: absolute;
  width: 40px;
  height: 28px;
  left: -5px;
  top: -5px;
  opacity: 0;
  cursor: pointer;
  z-index: 2;
}

.menu-toggle span {
  display: block;
  width: 28px;
  height: 3px;
  background-color: #44d62c;
  border-radius: 3px;

  transition: all 0.5s;
}

/* Hamburger menu animation */

.menu-toggle span:nth-child(2) {
  transform-origin: 0 0;
}
.menu-toggle span:nth-child(4) {
  transform-origin: 0 100%;
}

.menu-toggle input:checked ~ span:nth-child(2) {
  transform: rotate(45deg) translate(-1px, -1px);
}
.menu-toggle input:checked ~ span:nth-child(4) {
  transform: rotate(-45deg) translate(-1px, 0);
}
.menu-toggle input:checked ~ span:nth-child(3) {
  opacity: 0;
  transform: scale(0);
}

.tentang-responsive {
  display: none;
}

/* Responsive Breakpoint*/
/* =========================================================================== */

@media screen and (min-width: 800px) {
  .mainnav {
    padding-left: 30px !important;
    padding-right: 110px !important;
  }
  .container-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .navigation {
    padding: 0 80px !important;
  }
  .navigation span {
    font-size: 16px !important;
  }

  ul li.tentang:hover .hover-dropdown {
    min-width: 200px;
  }
}

@media screen and (min-width: 1024px) {
  .mainnav {
    padding-left: 30px !important;
    padding-right: 110px !important;
  }
  .container-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .navigation {
    padding: 0 80px !important;
  }
  .navigation span {
    font-size: 16px !important;
  }

  ul li.tentang:hover .hover-dropdown {
    min-width: 200px;
  }
}

@media screen and (min-width: 1154px) {
  .mainnav {
    padding-left: 30px !important;
    padding-right: 110px !important;
  }
  .container-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .navigation {
    padding: 0 80px !important;
  }
  .navigation span {
    font-size: 16px !important;
  }

  ul li.tentang:hover .hover-dropdown {
    min-width: 200px;
  }
}

@media screen and (min-width: 1360px) {
  .mainnav {
    padding-left: 30px !important;
    padding-right: 110px !important;
  }
  .container-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .navigation {
    padding: 0 80px !important;
  }
  .navigation span {
    font-size: 16px !important;
  }

  ul li.tentang:hover .hover-dropdown {
    min-width: 200px;
  }
}


@media screen and (min-width: 1280px) {
  .mainnav {
    padding-left: 30px !important;
    padding-right: 110px !important;
  }
  .container-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .navigation {
    padding: 0 80px !important;
  }
  .navigation span {
    font-size: 16px !important;
  }
  
  ul li.tentang:hover .hover-dropdown {
    min-width: 200px;
  }
}

@media screen and (min-width: 1440px) {
  .mainnav {
    padding-left: 30px !important;
    padding-right: 110px !important;
  }
  .container-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .navigation {
    padding: 0 120px !important;
  }
  .navigation span {
    font-size: 16px !important;
  }
}

@media screen and (min-width: 1600px) {
  .mainnav {
    padding-left: 50px !important;
    padding-right: 130px !important;
  }
  .container-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .navigation {
    padding: 0 140px !important;
  }
  .navigation span {
    font-size: 20px !important;
  }
}

@media screen and (min-width: 1920px) {
  .mainnav {
    padding-left: 70px !important;
    padding-right: 150px !important;
  }
  .container-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .navigation {
    padding: 0 160px !important;
  }
  .navigation span {
    font-size: 20px !important;
  }
}

@media screen and (max-width: 760px) {
  .old-logo {
    display: block;
  }

  .new-logo {
    display: none;
  }

  nav {
    /* display: none; */
    flex-direction: row;
    padding: 20px;
    align-items: flex-start;
    padding-top: 120px;
    height: 80px;
  }

  .navbarOnScroll {
    padding: 30px;
    height: 80px;
  }


  .navbarOnScroll .container-nav .slide  {
    padding: 60px 0 10px 20px;
  }

  .navbarOnScroll .logo {
    display: flex;
  }

  nav ul {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    background-color: #27328f;
    align-items: flex-start;
    padding-left: 15px;

    height: 60vh;
    width: 100%;

    justify-content: space-evenly;
    z-index: -1;

    transform: translateY(-150%);
    transition: all 1s;
  }

  nav .logo img {
    max-width: 250px;
    position: relative;
    z-index: 10;
  }

  nav ul.slide {
    transform: translateY(0);
    /* height: 100%; */
    margin-top: -230px;
    z-index: 100;
    padding-top: 500px;
    width: 100%;
  }

  nav ul.navigation {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  .mainnav {
    padding-left: 30px;
    padding-right: 30px;
  }
  .container-nav {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
  }

  .navigation {
    padding: 0 30px;
  }

  .menu-toggle {
    display: flex;
    position: relative;
    z-index: 10;
  }

  .tentang-kami {
    margin-top: -220px;
  }

  .tentang-kami span {
    text-align: left;
  }

  nav ul li {
    /* display: none; */
    width: 100%;
    height: auto;
    text-align: left;
    display: flex;
    justify-content: flex-start;
    position: relative;
  }

  nav ul li.tentang {
    display: flex;
    flex-direction: column;
  }

  nav ul li.tentang:hover {
    top: 27px;
  }

  nav ul li.arrows {
    display: flex;
    justify-content: flex-start;
  }

  ul li.tentang .hover-dropdown {
    position: relative;
    overflow: hidden;
    height: 0;
    border-radius: none;
    box-shadow: none;
    background-color: transparent;
    transition: ease in out 1s;
    z-index: 0;
    display: block;
    width: 100%;
    text-align: left;
  }
  ul li.tentang:hover .hover-dropdown {
    width: 96%;
    padding: 0px;
    height: auto;
    z-index: 2;
    display: block;
    gap: 10px;
    padding-left: 20px;
    margin-bottom: 15px;
  }

  ul li.tentang:hover .hover-dropdown a {
    margin: 5px;
    padding-left: 20px;
    color: #44d62c;
  }
  ul li.tentang:hover .hover-dropdown a:hover {
    color: white;
  }

  .arrows a:hover {
    color: #fff;
    margin-bottom: 0px;
    position: relative;
    top: 0px;
  }

  .arrows a,
  .arrows a:hover {
    text-decoration: none;
  }
  .arrows a:hover:after {
    display: none;
  }

  nav ul li.tentang {
    display: none;
  }

  .tentang-responsive {
    display: block;
    width: 100%;
  }

  button.accordion-button {
    width: 150%;
  }

  button.accordion-button.collapsed {
    width: 150%;
    margin-bottom: 20px;
  }

  .accordion-body {
    width: 200%;
  }

  nav ul li a {
    color: #44d62c;
    text-decoration: none;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 10px 0;
  }

  nav ul li button {
    background-color: #1c278b;
    border: none;
    color: #44d62c;
    text-decoration: none;
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 600;
    margin: 10px 0;
  }

  nav ul li .panel {
    padding-left: 50px;
    margin-bottom: 10px;
    gap: 10px;
  }

  .accordion {
    margin-top: 20px;
  }

  .accordion button {
    font-size: 12px;
  }


  .accordion-body {
    padding-bottom: 0;
    padding-top: 0;
    padding: 0 10px;
  }

  .accordion-body span {
    margin-top: 10px;
    margin-bottom: 10px;
  }

  .accordion-button:not(.collapsed) {
    color: #44d62c;
    background-color: #27328f;
  }

  .accordion-button {
    padding: 0px;
    background-color: #27328f;
    color: #44d62c;
  }

  .accordion-button:focus {
    outline: none;
    border: none;
    box-shadow: none;
  }

  .accordion-item {
    width: 100%;
    background-color: #27328f;
    outline: none;
    border: none;
  }

  .accordion-button::after {
    background-image: initial;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: initial;
  }

  div.accordion-body span {
    /* padding: 0; */
    width: 200%;
    font-size: 12px;
    color: #44d62c;
  }

  div.accordion-body a {
    text-decoration: none;
  }

  .accordion-body span.icon-dot {
    width: 20px;
  }
}
