.slick-list {
    overflow: visible !important;
  }
  
  
  .slick-slide {  
    position: relative;  
    z-index: 1; 
    /* your choice,  but make sure z-index of active slide is higher than this value */
  }  
  .slick-slide.slick-current {  
     z-index: 10;  
  }
  
      
  .slick-slide > div {
    margin: 0 50px;
  }
  .slick-list {
    margin: 0 -100px;
  }
  
  .slick-prev:before,
.slick-next:before {
  color: rgb(187, 183, 183, 0.7);
  z-index: 100;

}

button.slick-arrow.slick-next:before, button.slick-arrow.slick-prev:before {
  height:50px !important;
  width: 50px !important;
  font-size: 50px;
  margin-left: -13px !important; 
}
  
  
  /* https://merchant.razer.com/v3/channels/ */