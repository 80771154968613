.gradient-background {
  width: 100%;
  height: 100vh;
  background: radial-gradient(at top left, #1c278b, #35ad22, #0b1551, transparent 70%),
    linear-gradient(217deg, #0b1551, #1c278b, transparent 71%),
    linear-gradient(336deg, #44d62c, #0b1551, transparent 70%);

  background-size: 150% 125%;
  animation: gradient-animation 4s ease-in infinite;
}

/* #1c278b,
#44d62c,
#0b1551,
#35ad22 */

@keyframes gradient-animation {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.outer {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
