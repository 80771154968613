.menu-active {
    background-color: #edf2fe;
    color: #2d62ed;
  }
  
  .bg-active {
    background-image: url('../../../assets/images/Sprite_IconTabs_Blue.svg');
  }

  .accordion-body {
    padding-left: 30px;
  }