.slick-list {
  overflow: visible !important;
}


.slick-slide {  
  position: relative;  
  z-index: 1; 
  /* your choice,  but make sure z-index of active slide is higher than this value */
}  
.slick-slide.slick-current {  
   z-index: 10;  
}

	
.slick-slide > div {
  margin: 0 100px;
}
.slick-list {
  margin: 0 -100px;
}

.slick-slider .slick-dots.slick-thumb {
    width: 100%;

}

.slick-slider {
  position: relative !important;

}

.slick-dots.slick-thumb li {
  width: auto;
margin: 0 30px;
font-size: 16px;
top: 10px;
color:#2d62ed;
font-weight: 600;
}

.slick-dots.slick-thumb .slick-active {
height: 40px;  
font-weight: 600;
color:#44d62c;
/* background-color: red; */
background-image: url(../../../assets/images/icon-active-navbar.svg);
background-repeat: no-repeat;
background-position: center bottom;
}

.slick-prev:before,
.slick-next:before {
  color: rgb(187, 183, 183, 0.7);
  position: relative !important;
  z-index: 10 !important;

}

button.slick-arrow.slick-next:before, button.slick-arrow.slick-prev:before {
  height:30px !important;
  width: 30px !important;
  font-size: 30px;
  margin-left: -5px !important; 
  position: relative !important;
  z-index: 10 !important;
}

button.slick-arrow.slick-next:before, button.slick-arrow.slick-prev:before {
  height:30px !important;
  width: 30px !important;
  font-size: 30px;
  margin-left: -5px !important; 
  position: relative !important;
  z-index: 10 !important;
}

@media screen and (max-width: 768px){
  .slick-dots.slick-thumb li{
    margin: 0px 10px;

  }

  .slick-dots.slick-thumb .slick-active {

    color:#44d62c;
    /* background-color: red; */
    background-image: none;

    }

    .slick-dots.slick-thumb {
      width: auto;
    margin: 0 30px;
    font-size: 16px;
    top: 10px;
    left: -30px;
    color:#2d62ed;
    font-weight: 600;
    }

}

/* https://merchant.razer.com/v3/channels/ */