.accordions {
    width: 100%;
    margin: 2rem auto;
    gap: 50px;
    background-color: #f4f4f4;
    padding: 40px 20px 20px 20px;
    border-radius: 5px;
    border: solid 1px #cfd1d4;
  }

  .accordions img {
    margin-bottom: 30px;
  }
  
  .accordion-titles {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    cursor: pointer;
    background-color: #fff;
    margin-bottom: 2px;
    font-weight: 800;
  }
  
  .accordion-titles:hover {
    background-color: #fff;
  }
  
  .accordion-titles,
  .accordion-contents {
    padding: 1rem;
    color: #1C278B;
    border-radius: 5px;
  }
  
  .accordion-contents {
    background-color: #fff;
    padding-left: 40px;
    color: #272d3b;
}

  .accordion-items {
    margin-bottom: 20px;
  }

  .arrow {
    height: 10px !important;
    width: auto !important;
    margin: 0 !important;
  }

  .bank {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: left;
  color: var(--272-d-3-b-text);
  }