.container {
    display: flex;
    width: 100%;
    padding: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 70px;
}

.container h1 {
  align-self: flex-start;
  padding-left: 23px;
  font-family: Poppins;
  font-size: 32px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--272-d-3-b-text);
}

.react-tabs {
  display: flex;
  margin-left: 36px;
  width: 100%;
  height: 700px;
  /* border: 1px solid #3c3e43; */
  color: white;
  /* background: #1c1d1f; */
}

.react-tabs__tab-list {
  display: flex;
  flex-direction: column;
  width: 300px;
  margin: 0;
  padding: 0;
  gap: 30px;
  color: white;
  height: 100%;
  justify-content: center;
  /* background: lightgray; */
  /* background: #3c3e43; */
}

.react-tabs__tab {
  height: 60px;

  list-style: none;
  padding: 12px 6px;
  cursor: pointer;
  border: none;
  width: 100%;
  border-radius: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* height: 100%; */
  /* color: #bbb; */
}

.react-tabs__tab--selected {
  /* background: #1c1d1f; */
  /* background: #3c3e43; */

  /* border-color: #1c1d1f; */
  /* border-left: 4px solid #6eb800; */
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #edf2fe;
  outline: none;
}

.react-tabs__tab-panel {
  display: none;
  width: 100%;
  height: 100%;
}

.react-tabs__tab-panel--selected {
  display: block;
}

.react-tabs__tab {
  /* padding-left: 24px; */
}

.react-tabs__tab--selected {
  /* padding-left: 21px; */
}

.panel-content {
  /* width: 700px; */
  height: 100%;
  /* padding: 50px; */
}

.icon {
    /* width: auto;
    height: 50px; */
}