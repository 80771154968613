.wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
}

.content-wrapper {
    width: 35%;
    height: 65%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-sizing: border-box;
    gap: 50px;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 50px;
    box-sizing: border-box;
}

.icon-mbayar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    margin-top: 100px ;
}

.mbayar-title {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#pilih-sistem {
    margin: 30px 0;
}

.store {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 100px;
}

#copyright {
    padding: 0;
    margin-top: 50px;
}

@media screen and (max-width: 1380px) {
    .wrapper {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    background-color: #f2f2f2;
}

.content-wrapper {
    width: 35%;
    height: 65%;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 20px;
    box-sizing: border-box;
    gap: 50px;
}

.content {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 30px;
    box-sizing: border-box;
}

.icon-mbayar {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 30px;
    margin-top: 50px ;
}

.mbayar-title {
    font-size: 20px;
    font-weight: 600;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#pilih-sistem {
    margin: 30px 0;
}

.store {
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 100px;
}

#copyright {
    padding: 0;
    margin-top: -50px;
}

}